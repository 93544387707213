import { Autocomplete, Box, Grid, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography, useMediaQuery, useTheme } from '@mui/material';
import { PropsDatosPagadores } from '../../shared/VinculacionesModels';
import companies from '../../shared/companies.json';
import DeleteIcon from '@mui/icons-material/Delete';

const PagadoresForm: React.FC<PropsDatosPagadores> = ({ datosPagadores, setDatosPagadores }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const handleDelete = (index: number) => {
        setDatosPagadores(datosPagadores.filter((_, rowIndex) => rowIndex !== index));
    };
    const handleChange = (event: any, value: any[]) => {
        if (value != null) {
            setDatosPagadores(value);
        }
    };
    const filteredOptions = companies.filter((option) => {
        return option.nit.toLowerCase() || option.empresa.toLowerCase();
    });
    return (
        <>
            <Typography variant='h6' sx={{ textAlign: 'left', mb: 3, mt: 3, fontWeight: '500' }}>
                Busca a tus clientes. Agrega todos los que quieras:
            </Typography>
            <Grid container spacing={isMobile ? 1 : 2}>
                <Grid item xs={12} md={12} sx={{ p: !isMobile ? 1 : 0, mt: 1 }}>
                    <Autocomplete
                        multiple
                        options={filteredOptions}   
                        renderTags={(tags,getTagProps)=> null}
                        getOptionLabel={(option) =>`${option.nit} - ${option.empresa}`}
                        value={datosPagadores} 
                        onChange={handleChange} 
                        clearIcon={null}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Buscar"
                            />
                        )}
                    />
                </Grid>
                {datosPagadores.length != 0 &&
                    <Grid item xs={12} md={12} sx={{ p: !isMobile ? 1 : 0, mt: 1 }}>
                        <TableContainer component={Paper}>
                            <Table size="small" aria-label="a dense table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>RAZÓN SOCIAL</TableCell>
                                        <TableCell>NIT</TableCell>
                                        <TableCell></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {datosPagadores.map((row, index) => (
                                        <TableRow
                                            key={index}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell component="th" scope="row">
                                                {row.empresa}
                                            </TableCell>
                                            <TableCell>
                                                {row.nit}
                                            </TableCell>
                                            <TableCell align="right">
                                                <Box sx={{ display: 'flex', justifyContent: 'right' }}>
                                                    <IconButton
                                                        size='small'
                                                        sx={{ color: '#a90000' }}
                                                        onClick={() => handleDelete(index)}
                                                    >
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </Box>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                }
            </Grid>
        </>
    )
}

export default PagadoresForm